// Groups
const TYP_INWARD = 0;
const TYP_OUTWARD = 1;

export default {
    TYP_INWARD,
    TYP_OUTWARD,
    types: [
        {
            title: "Inwards",
            value: TYP_INWARD,
        },
        {
            title: "Outwards",
            value: TYP_OUTWARD,
        },
    ]
}