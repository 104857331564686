<template>
    <div class="mt-2">
        <v-overlay :model-value="loading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <div class="mb-2">
            <v-card color="blue-grey-darken-1" variant="flat" class="text-center w-25">
                <template v-slot:subtitle>
                    INVOICED TODAY
                </template>
                <template v-slot:text>
                    <v-skeleton-loader v-if="loading" type="avatar"></v-skeleton-loader>
                    <h2 v-if="!loading">${{ numberWithCommas(data.total, 2) }}</h2>
                </template>
            </v-card>
        </div>
        <v-card variant="flat" class="pa-4 my-2">
            <div>
                <v-text-field label="Search..."
                              prepend-icon="mdi-magnify"
                              v-model="searchTerm"
                              density="compact"
                              clearable></v-text-field>
            </div>
            <v-table>
                <thead>
                    <tr>
                        <th style="width:8%">Job</th>
                        <th style="width:18%">Cust#</th>
                        <th style="width:18%">Customer</th>
                        <th style="width:8%">Invoice Date</th>
                        <th style="width:8%">Task</th>
                        <th style="width:8%">Total</th>
                        <th style="width:18%"></th>
                        <th style="width:8%"></th>
                    </tr>
                </thead>
                <tbody v-if="data">
                    <tr v-for="job in filteredJobs" :key="job.id">
                        <td>
                            <job-link :job="job" :search-term="searchTerm"></job-link>
                        </td>
                        <td>
                            <span v-html="formatSearchName(job.customerNumberFull, searchTerm)"></span>
                        </td>
                        <td>
                            <contact-link :contact="job.contact" :search-term="searchTerm"></contact-link>
                        </td>
                        <td>
                            <span v-if="job.maxDateCompleted">{{ $dayjs(job.maxDateCompleted).format("DD/MM/YYYY") }}</span>
                        </td>
                        <td>
                            {{ job.workType }}
                        </td>
                        <td>
                            ${{ numberWithCommas(job.jobSubTotal,2)}}
                        </td>
                        <td>
                            <xero-invoice-link :id="job.xeroInvoiceId"></xero-invoice-link>
                        </td>
                        <td>
                            <v-btn v-if="job.status == $constants.jobs.JOBSTAT_INVOICE"
                                   prepend-icon="mdi-invoice-text-outline"
                                   class="ml-1"
                                   variant="tonal"
                                   color="info"
                                   @click="markInvoiced(job)">Mark Invoiced</v-btn>
                            <v-btn v-if="job.status > $constants.jobs.JOBSTAT_INVOICE"
                                   prepend-icon="mdi-check-circle"
                                   class="ml-1"
                                   color="secondary"
                                   variant="tonal"
                                   disabled>Job Invoiced</v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-card>
    </div>
</template>
<script>
    import { mapActions } from "vuex";
    import { numberWithCommas } from "../../mixins/numberMixins";
    import { formatSearchName } from "../../mixins/searchMixins";
    function initialState() {
        return {
            loading: false,
            today: null,
            data: [],
            searchTerm: '',
        };
    }
    export default {
        name: "StatusJobs",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadData();
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "updateSmartJobStatus"
            ]),
            formatSearchName,
            numberWithCommas,
            loadData() {
                this.loading = true;
                this.$api.get('/dashboard/invoice')
                    .then(res => {
                        this.data = res.data;
                        this.loading = false;
                    }).catch(error => {
                        this.loading = false;
                        this.showErrorSnack("There was an issue getting the jobs.");
                    });
            },
            markInvoiced(job) {
                job.status = this.$constants.jobs.JOBSTAT_COMP;
                this.data.total += job.jobSubTotal;
                this.updateSmartJobStatus({ job: job, status: this.$constants.jobs.JOBSTAT_COMP });
            },
        },
        computed: {
            filteredJobs() {
                if (this.data && this.data.jobs) {
                    if (this.searchTerm && this.searchTerm.length > 0) {
                        let upperSearch = this.searchTerm.toUpperCase();
                        return this.data.jobs.filter(item => {
                            return item.customerNumberFull.toUpperCase().includes(upperSearch) || item.name.toUpperCase().includes(upperSearch) || item.contact.name.toUpperCase().includes(upperSearch);
                        });
                    }
                    return this.data.jobs;
                }
                return [];
            }
        },
        watch: {
        }
    };
</script>
<style scoped>
</style>