<template>
    <v-card class="job-card" :id="'card-'+job.id" :class="{'moveable-card':(!job.archived && ($windowWidth > 960 || !smartLock) && !job.activeTask.running), 'locked-job-card':job.archived }">
        <div v-if="job.archived" class="locked-card">
            <v-icon icon="mdi-lock" class="mr-1" size="x-small"></v-icon> LOCKED
        </div>
        <v-card-title>
            <span v-html="formatSearchName(job.name, searchTerm)"></span>
        </v-card-title>
        <v-card-subtitle>
            <contact-link :contact="job.contact" :search-term="searchTerm"></contact-link>
        </v-card-subtitle>
        <v-card-text>
            <div v-if="job.customerNumber">
                <small class="text-grey-darken-2"><v-icon icon="mdi-pound-box-outline"></v-icon></small> <span v-html="formatSearchName(job.customerNumber, searchTerm)"></span>
            </div>
            <div v-if="job.dateTargetCompletion">
                <small class="text-grey-darken-2"><v-icon icon="mdi-bullseye"></v-icon></small> {{ $dayjs(job.dateTargetCompletion).format("DD/MM") }}
            </div>
            <div v-if="job.colours">
                <small class="text-grey-darken-2"><v-icon icon="mdi-palette"></v-icon></small> <span v-html="formatSearchName(job.colours.replace('Duralloy ', ''), searchTerm)"></span>
            </div>
            <div v-if="job.activeTask && job.activeTask.assignedLocation">
                <small class="text-grey-darken-2"><v-icon icon="mdi-map-marker-radius"></v-icon></small> {{ job.activeTask.assignedLocation }}
            </div>
            <div class="d-flex justify-space-between">
                <div>
                    <div><small class="text-grey-darken-2"><v-icon icon="mdi-invoice-text-outline"></v-icon></small> ${{ numberWithCommas(job.taskSubTotal, 2) }}</div>
                </div>
                <v-tooltip text="View Job" class="float-right">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" icon="mdi-briefcase-eye" variant="plain" size="x-small" @click="openSmartJobDialog(job)"></v-btn>
                    </template>
                </v-tooltip>
            </div>
        </v-card-text>
        <div v-if="job.activeTask && job.activeTask.status == $constants.jobs.JOBSTAT_STRIPPING && job.activeTask.running" class="stripping-card">
            <v-icon icon="mdi-progress-check" size="x-small"></v-icon> CURR.STRIPPING
        </div>
        <div v-if="job.activeTask && job.activeTask.status == $constants.jobs.JOBSTAT_BLASTING && job.activeTask.running" class="stripping-card">
            <v-icon icon="mdi-progress-check" size="x-small"></v-icon> CURR.BLASTING
        </div>
        <div v-if="(!job.dateReceived || job.dateReceived.length == 0) && job.jobStatus < $constants.jobs.JOBSTAT_INVOICE" class="received-card">
            <v-icon icon="mdi-map-marker-off" size="x-small"></v-icon> NOT RECEIVED
        </div>
        <div v-if="job.datePretreatment && job.jobStatus < $constants.jobs.JOBSTAT_INVOICE" class="pretreated-card">
            <v-icon icon="mdi-progress-check" size="x-small"></v-icon> PRETREATED
        </div>
        <div v-if="job.jobTag == $constants.jobs.TAG_REWORK" class="rework-card">
            <v-icon icon="mdi-recycle" size="x-small"></v-icon> REWORK
        </div>
        <div v-if="job.jobTag == $constants.jobs.TAG_BATCH" class="batch-card">
            <v-icon icon="mdi-toaster-oven" size="x-small"></v-icon> BATCH OVEN
        </div>
        <div v-if="job.jobTag == $constants.jobs.TAG_HIGH && job.jobStatus < $constants.jobs.JOBSTAT_INVOICE" class="priority-card">
            <v-icon icon="mdi-flag" size="x-small"></v-icon> HIGH PRIORITY
        </div>
        <div v-if="job.jobStatus == $constants.jobs.JOBSTAT_COMP" class="invoice-card">
            <v-icon icon="mdi-progress-check" size="x-small"></v-icon> INVOICED
        </div>
        <div v-if="job.jobStatus == $constants.jobs.JOBSTAT_INVOICE" class="completed-card">
            <v-icon icon="mdi-progress-check" size="x-small"></v-icon> COMPLETED
        </div>
    </v-card>
</template>
<style scoped lang="scss">
    @import '../../../styles/smart.scss';
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import { formatSearchName } from "../../../mixins/searchMixins";
    import { numberWithCommas } from "../../../mixins/numberMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
        };
    }
    export default {
        name: "SmartCard",
        data: function () {
            return initialState();
        },
        props: {
            job: Object,
            searchTerm: String
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "openSmartJobDialog",
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            formatSearchName,
            numberWithCommas
        },
        computed: {
            ...mapState({
                smartLock: (state) => state.smart.smartLock,
            }),
        }
    };
</script>