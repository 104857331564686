<template>
    <v-main>
        <div class="pa-4" style="height: 100vh">
            <div>
                <v-card class="w-100"
                        elevation="1"
                        rounded="lg">
                    <v-img height="100"
                           style="background:black;"
                           src="/assets/logo.png"></v-img>
                    <v-card-text>
                        <div style="min-height:600px">
                            <v-overlay :model-value="loading"
                                       contained 
                                        persistent
                                       class="align-center justify-center">
                                <v-progress-circular color="primary"
                                                     indeterminate
                                                     size="64"></v-progress-circular>
                            </v-overlay>
                            <v-alert icon="mdi-alert-octagram"
                                     title="Sorry"
                                     v-if="showError"
                                     text="We cannot access any information with the provided code. Please contact P-88 on 09 836 4456."
                                     type="warning"></v-alert>
                            <div v-if="data">
                                <h2>{{ data.contact }}</h2>
                                <v-divider class="my-2"></v-divider>
                                <p>Below is a list of your current active jobs.</p>
                                <div>
                                    <v-text-field clearable
                                                  density="compact"
                                                  ref="searchInput"
                                                  hide-details
                                                  v-model="searchTerm"
                                                  label="Search jobs..."
                                                  prepend-icon="mdi-magnify"></v-text-field>
                                </div>
                                <v-table density="compact">
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Status
                                            </th>
                                            <th class="text-left">
                                                Created
                                            </th>
                                            <th class="text-left">
                                                Job
                                            </th>
                                            <th v-if="hasContactUser" class="text-left">
                                                Target Customer Pickup
                                            </th>
                                            <th class="text-left">
                                                Target Completion
                                            </th>
                                            <th class="text-left">
                                                Colour
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="job in filteredData" :key="job.jobNumber">
                                            <td>
                                                <v-chip v-if="job.status >= $constants.jobs.JOBSTAT_INVOICE" variant="flat" color="green-lighten-2" size="x-small">
                                                    <v-icon start icon="mdi-check-circle"></v-icon>
                                                    Completed
                                                </v-chip>
                                                <v-chip v-if="job.status < $constants.jobs.JOBSTAT_INVOICE" variant="flat" color="blue-accent-1" size="x-small">
                                                    <v-icon start icon="mdi-spray"></v-icon>
                                                    In Progress
                                                </v-chip>
                                            </td>
                                            <td>{{ $dayjs(job.dateCreated).format("DD/MM/YYYY") }}</td>
                                            <td><span v-html="formatSearchName(job.customerNumber, searchTerm)"></span> 
                                                <v-chip v-if="job.pretreated" prepend-icon="mdi-shower-head" variant="flat" color="blue-darken-2" size="x-small" class="mr-1">PRETREATED</v-chip>
                                            </td>
                                            <td v-if="hasContactUser">
                                                <v-text-field type="date"
                                                              density="compact"
                                                              v-model="job.dateCustomerTargetCompletion"
                                                              @change="customerDateChange(job)"></v-text-field>
                                            </td>
                                            <td>
                                                <span v-if="job.dateTargetCompletion">{{ $dayjs(job.dateTargetCompletion).format("DD/MM/YYYY") }}</span>
                                                <span v-if="!job.dateTargetCompletion">Not scheduled</span>
                                            </td>
                                            <td><span v-html="formatSearchName(job.colours, searchTerm)"></span></td>
                                        </tr>
                                    </tbody>
                                </v-table>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-main>
</template>
<style>
    
</style>
<script>
    import axios from 'axios'
    import { mapActions, mapState } from "vuex";
    import { formatSearchName } from "../mixins/searchMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            showError: false,
            data: null,
            searchTerm: ''
        };
    }
    export default {
        name: "CustomerJobs",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadCustomerJobs();
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showError", "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            formatSearchName,
            loadCustomerJobs() {
                if (this.hasContactUser) {
                    this.$api.get('externalcustomer/customerjobs').then(res => {
                        this.loading = false;
                        this.data = res.data;
                    }).catch(error => {
                        this.loading = false;
                        this.showError = true;
                    });
                } else {
                    let custCode = this.$route.query.custcode;
                    if (process.env.VUE_APP_API_CODE != this.$route.query.code || !custCode || custCode.length <= 0) {
                        this.showError = true;
                    } else {
                        this.loading = true;
                        axios.get(process.env.VUE_APP_API_URL + 'external/customerjobs?code=' + custCode).then(res => {
                            this.loading = false;
                            this.data = res.data;
                        }).catch(error => {
                            this.loading = false;
                            this.showError = true;
                        });
                    }
                }
            },
            customerDateChange(job){
                this.$api.patch('/externalcustomer/'+job.id, [{
                        op: "replace",
                        path: "/dateCustomerTargetCompletion",
                        value: job.dateCustomerTargetCompletion
                    }]).then(res => {
                        this.showSuccessSnack("Requested date updated!");
                        const index = this.data.jobs.findIndex(obj => obj.id === res.id);
                        this.data.jobs.splice(index, 1, res);
                    });
            }
        },
        computed: {
            ...mapState({
                user: (state) => state.auth.user,
            }),
            hasContactUser() {
                return this.user && this.user.contactId;
            },
            filteredData() {
                if (this.data && this.data.jobs) {
                    if (this.searchTerm.length > 0) {
                        let upperSearch = this.searchTerm.toUpperCase();
                        return this.data.jobs.filter(item => {
                            return item.customerNumber.toUpperCase().includes(upperSearch) || item.colours.toUpperCase().includes(upperSearch);
                        });
                    }
                    return this.data.jobs;
                }
                return [];
            },
        }
    };
</script>