<template>
    <div>
        <div class="page">
            <div class="subpage">
                <div class="w-100 d-flex justify-space-between mb-0">
                    <div>
                        <div class="d-flex justify-space-between">
                            <v-img height="70"
                                   width="70"
                                   style="background:black;"
                                   src="/assets/logo.png"></v-img>
                            <h4 class="ml-2">{{ $dayjs(job.created).format('ddd D MMM YYYY') }}</h4>
                        </div>
                    </div>
                    <div class="d-flex justify-space-between">
                        <div class="mr-2 text-right">
                            <h1>{{ job.name }}</h1>
                            <h4 class="font-italic">PRODUCTION SHEET</h4>
                        </div>
                        
                        <job-qr-code :job-id="job.id"></job-qr-code>
                    </div>

                </div>
                <div class="customer-box mt-0">
                    <h2>{{ job.contact.name }}</h2>
                    <h3>{{ job.customerNumber }}</h3>
                </div>
                <div class="d-flex justify-space-between">
                    <div style="width:48%">
                        <table class="detail-table">
                            <tbody>
                                <tr>
                                    <th class="text-left">Description</th>
                                    <th class="text-right">Quantity</th>
                                </tr>
                                <tr v-for="item in dataItems" :key="item.description">
                                    <td>
                                        <span v-html="item.description.replaceAll('@', '<br />@')"></span>
                                    </td>
                                    <td class="text-right">
                                        {{ item.quantity }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pl-4" style="width:52%">
                        <div class="w-100 mt-2">
                            <h5 class="box-header"><v-icon icon="mdi-check-underline-circle-outline" class="mr-1"></v-icon> QA CHECKLIST</h5>
                            <div class="customer-box qa-box" style="margin-top:0px;">
                                <b>STRIPPING</b>
                                <ul style="list-style-type: none;" class="mb-1">
                                    <li v-for="question in stripQuestions" :key="question"><v-icon icon="mdi-square-rounded-outline"></v-icon> {{ question }}</li>
                                    <li>
                                        <div class="d-flex justify-space-between collection-details mt-2 mb-1">
                                            <div class="enter-section">
                                                Name:<span class="line"></span>
                                            </div>
                                            <div class="enter-section">
                                                Date:<span class="line" style="width:80px"></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <b>BLASTING</b>
                                <ul style="list-style-type: none;" class="mb-1">
                                    <li v-for="question in blastQuestions" :key="question"><v-icon icon="mdi-square-rounded-outline"></v-icon> {{ question }}</li>
                                    <li>
                                        <div class="d-flex justify-space-between collection-details mt-2 mb-1">
                                            <div class="enter-section">
                                                Name:<span class="line"></span>
                                            </div>
                                            <div class="enter-section">
                                                Date:<span class="line" style="width:80px"></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <b>PRETREATMENT</b>
                                <ul style="list-style-type: none;" class="mb-1">
                                    <li v-for="question in pretreatQuestions" :key="question"><v-icon icon="mdi-square-rounded-outline"></v-icon> {{ question }}</li>
                                    <li>
                                        <div class="d-flex justify-space-between collection-details mt-2 mb-1">
                                            <div class="enter-section">
                                                Name:<span class="line"></span>
                                            </div>
                                            <div class="enter-section">
                                                Date:<span class="line" style="width:80px"></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <b>POWDER</b>
                                <ul style="list-style-type: none;" class="mb-1">
                                    <li v-for="question in powderQuestions" :key="question"><v-icon icon="mdi-square-rounded-outline"></v-icon> {{ question }}</li>
                                    <li>
                                        <div class="d-flex justify-space-between collection-details mt-2 mb-1">
                                            <div class="enter-section">
                                                Name:<span class="line"></span>
                                            </div>
                                            <div class="enter-section">
                                                Date:<span class="line" style="width:80px"></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <b>PACKING</b>
                                <ul style="list-style-type: none;" class="mb-1">
                                    <li v-for="question in packingQuestions" :key="question"><v-icon icon="mdi-square-rounded-outline"></v-icon> {{ question }}</li>
                                    <li>
                                        <div class="d-flex justify-space-between collection-details mt-2 mb-1">
                                            <div class="enter-section">
                                                Name:<span class="line"></span>
                                            </div>
                                            <div class="enter-section">
                                                Date:<span class="line" style="width:80px"></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="customer-box">
                            <table class="detail-table mt-0">
                                <tr>
                                    <th class="w-80 text-left">POWDER BATCH</th>
                                    <th class="w-20 text-left">KGs</th>
                                </tr>
                                <tr v-for="i in [1,2]" :key="i">
                                    <td>&nbsp;</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                        <div class="customer-box qa-box">
                            <div class="font-weight-bold">
                                Collected By
                            </div>
                            <div class="mt-3">
                                Name:<span class="line" style="width:250px"></span>
                            </div>
                            <div class="mt-3">
                                Signature:<span class="line" style="width:230px"></span>
                            </div>
                            <div class="mt-3">
                                Date:<span class="line" style="width:255px"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page">
            <div class="subpage">
                <div class="w-100 d-flex justify-space-between mb-3">
                    <h1>{{ job.name }}</h1>
                    <div>
                        <v-img height="50"
                               width="50"
                               style="background:black;"
                               src="/assets/logo.png"></v-img>
                    </div>
                </div>
                <div class="w-100 d-flex justify-space-between">
                    <h3>{{ $dayjs(job.created).format('ddd D MMM YYYY') }}</h3>
                    <h3 class="font-italic">PACKING SHEET</h3>
                </div>
                <div class="customer-box">
                    <h2>{{ job.contact.name }}</h2>
                    <h3>{{ job.customerNumber }}</h3>
                </div>
                <table class="detail-table">
                    <tr>
                        <th class="text-left">Description</th>
                        <th class="text-right">Quantity</th>
                    </tr>
                    <tr v-for="item in dataItems" :key="item.description">
                        <td>
                            {{ item.description }}
                        </td>
                        <td class="text-right">
                            {{ item.quantity }}
                        </td>
                    </tr>
                </table>
                <div class="d-flex justify-space-between collection-details">
                    <div>
                        Packed By
                    </div>
                    <div>
                        Name:<span class="line"></span>
                    </div>
                    <div>
                        Signature:<span class="line"></span>
                    </div>
                    <div>
                        Date:<span class="line" style="width:80px"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
    @import '../../styles/portraitprint.scss';
    .customer-box {
        width: 100%;
        border: 1px solid black;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 5px;
    }
    .box-header {
        width: 100%;
        padding: 5px;
        border-left: 1px solid black;
        border-right: 1px solid black;
        border-top:1px solid black;
        font-family:monospace;
    }
    .qa-box {
        font-size:11px;
    }

    .customer-box .header {
        font-size: 12px;
        display: inline-block;
        width: 100%;
        text-decoration: underline;
    }

    .collection-details {
        margin-top: 15px;
        font-size: 12px;
    }

    .line {
        display: inline-block;
        width: 150px;
        border-bottom: 1px solid black;
        margin-left: 3px;
    }
</style>
<script>
    function initialState() {
        return {
            loading: false,
            stripQuestions : [
                "Product is fully stripped & free of residual chemical",
                "Product quantity and overall quality is correct",
            ],
            blastQuestions: [
                "Product is fully blasted with even finish texture",
                "Product quantity and overall quality is correct",
            ],
            pretreatQuestions: [
                "Product is free of plastic or any other contamination before treatment",
                "Any damage has been notified to office before being run",
                "Correct treatment method used for product type",
            ],
            powderQuestions: [
                "Product is free of contamination and damage",
                "Any damage has been notified to office before being run",
                "Hanging jigs are clean and in good condition",
                "Correct powder selected",
                "Correct equipment settings used for product and powder type",
            ],
            packingQuestions: [
                "Powder thickness checks are complete",
                "Product quantity and overall quality is correct",
                "Product is correctly packaged as per customer expectations",
                "Product location and pack quantity correctly noted"
            ]
        };
    }
    export default {
        name: "JobCard",
        data: function () {
            return initialState();
        },
        mounted: function () { 
        },
        props: {
            job: Object
        },
        methods: {
        },
        computed: {
            dataItems() {
                if (this.job){
                    if (this.job.jobItemTasks && this.job.jobItemTasks.length > 0){
                        return this.job.jobItemTasks.map(item => {
                            return { description: item.itemDescription, quantity: item.quantity };
                        });
                    } else {
                        return this.job.jobItems;
                    }
                }
                return [];
            }
        }
    };
</script>