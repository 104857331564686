<template>
    <v-form v-model="valid" @submit.prevent="submit" :disabled="loading">
        <v-text-field label="Transaction Date"
                      type="date"
                      v-model="editTransaction.transactionDate"></v-text-field>
        <v-select label="Type"
                  v-model="editTransaction.type"
                  :items="$constants.warehouse.types"></v-select>
        <b><v-icon icon="mdi-camera-image" class="mr-1"></v-icon> Photos</b>
        <p>Please take multiple, clear photos of what is coming in or out.</p>
        <files-panel :object="editTransaction" :entity="$constants.entities.WarehouseTransaction"></files-panel>
        <hr class="my-2" />
        <div v-if="editTransaction.type == $constants.warehouse.TYP_INWARD">
            <h4>Inward Details</h4>
            <p>Search for the customer who's job is being received.</p>
            <v-text-field :label="'Search for the '+$constants.entities.Contact.SINGULAR+'...'" 
                          v-model="contactSearchTerm" 
                          :hide-details="true"
                          :loading="contactSearching"></v-text-field>
            <v-list lines="one">
                <v-list-item v-for="option in contactSearchResults"
                             :key="option.id"
                             :value="option"
                             :active="activeContact(option)"
                             @click="selectContact(option)"
                             color="primary">
                    <template v-slot:prepend>
                        <v-icon :icon="activeContact(option) ? 'mdi-check-circle-outline' : 'mdi-circle-outline'"></v-icon>
                    </template>
                    <template v-slot:title>
                        <span v-html="formatSearchName(option.name, contactSearchTerm)"></span>
                    </template>
                    <template v-slot:subtitle>
                        <div><i>Email: </i><span v-html="formatSearchName(option.accountsEmail, contactSearchTerm)"></span></div>
                        <div><i>Phone: </i><span v-html="formatSearchName(option.phone, contactSearchTerm)"></span></div>
                    </template>
                </v-list-item>
            </v-list>
            <div v-if="contactSearchResults.length == 0 && contactSearchTerm.length > 2 && !contactSearching">
                <i>No {{ $constants.entities.Contact.PLURAL }} found. If you think this is a new customer please type the full {{ $constants.entities.Contact.SINGULAR }} below.</i>
                <v-text-field label="New Customer Name"
                              :hide-details="true"
                              v-model="newCustomerName"></v-text-field>
            </div>
        </div>
        <div v-if="editTransaction.type == $constants.warehouse.TYP_OUTWARD">
            <h4>Outward Details</h4>
        </div>
        <v-alert density="compact"
                 v-if="errors"
                 type="warning"
                 class="my-2"
                 :title="errors.title">
            <ul>
                <li v-for="error in errors.errors" :key="error" v-html="error[0]"></li>
            </ul>
        </v-alert>
        <div class="mt-2 pt-2">
            <v-btn :loading="loading"
                   type="submit"
                   :disabled="!valid"
                   flat
                   color="primary"
                   prepend-icon="mdi-floppy">SAVE</v-btn>
        </div>
    </v-form>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import { formatSearchName } from "../mixins/searchMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            valid: false,
            errors: null,
            editTransaction: {
                id: 0,
                transactionDate: null,
                type: null,
                contact: null
            },
            contactSearchTerm: '',
            contactSearching: false,
            contactSearchResults: [],
            contactSearchTimer: null,
            newCustomerName: ''
        };
    }
    export default {
        name: "WarehouseTransactionForm",
        data: function () {
            return initialState();
        },
        mounted: function () {
            if (typeof this.transaction !== 'undefined') {
                this.editTransaction = this.transaction;
            } else {
                this.editTransaction.transactionDate = this.$dayjs().format('YYYY-MM-DD');
            }
        },
        props: {
            transaction: Object
        },
        methods: {
            ...mapActions([
                "showSuccessSnack",
                "showErrorSnack",
                "reloadEntities"
            ]),
            formatSearchName,
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            submit() {
                this.loading = true
                let formData = new FormData();
                let config = {
                    method: 'post',
                    url: this.entity.CREATE,
                    data: new FormData()
                }
                if (this.editEntity != null) {
                    formData.append('id', this.editEntity.id);
                    config = {
                        method: 'put',
                        url: this.entity.UPDATE + this.editEntity.id,
                        data: new FormData()
                    }
                } else {
                    formData.append('defaultRate', this.rate);
                }
                formData.append('name', this.name);
                config.data = formData;
                this.$api(config)
                    .then(res => {
                        this.loading = false;
                        this.showEntityForm = false;
                        this.reloadEntities();
                        this.reset();
                        this.showSuccessSnack("Updated!");
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else {
                            this.errors = { title: "Whoops", errors: [["We hit an error. Please contact support."]] };
                        }
                        this.loading = false;
                    });
            },
            searchContacts() {
                this.contactSearching = true;
                this.$api.get(this.$constants.entities.Contact.ROUTE + "?searchTerm=" + this.contactSearchTerm)
                    .then(res => {
                        this.contactSearching = false;
                        this.contactSearchResults = res.data.items;
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else {
                            this.errors = { title: "Whoops", errors: [["We hit an error. Please contact support."]] };
                        }
                        this.contactSearching = false;
                    });
            },
            selectContact(option) {
                if (this.editTransaction.contact == option) {
                    this.editTransaction.contact = null;
                } else {
                    this.editTransaction.contact = option;
                }
            },
            activeContact(option) {
                return this.editTransaction.contact != null && this.editTransaction.contact.id == option.id;
            },
        },
        computed: {
            ...mapState({
            }),
        },
        watch: {
            contactSearchTerm: {
                handler: function () {
                    clearTimeout(this.contactSearchTimer);
                    if (this.contactSearchTerm.length > 2) {
                        if (this.contactSearchTerm.length > this.newCustomerName.length){
                            this.newCustomerName = this.contactSearchTerm;
                        }
                        this.contactSearchTimer = setTimeout(this.searchContacts, 100);
                    } else {
                        this.contactSearchResults = [];
                    }
                }
            },

        }
    };
</script>