import { shallowRef, ref, computed } from 'vue'
import ContactForm from '../../forms/ContactForm.vue'
import ContactDetailsPanel from '../../components/panels/ContactDetailsPanel.vue'
import JobTablePanel from '../../components/panels/JobTablePanel.vue'
import InvoicesTablePanel from '../../components/panels/InvoicesTablePanel.vue'
import MailPanel from '../../components/panels/MailPanel.vue'
import ContactPerformancePanel from '../../components/panels/ContactPerformancePanel.vue'

export default {
    ICON: 'mdi-account',
    TYPE: 'Contact',
    SINGULAR: 'Customer',
    PLURAL: 'Customers',
    ROUTE: 'contacts/',
    DELETE: 'contacts/',
    CREATE: 'contacts/',
    UPDATE: 'contacts/',
    FORM: shallowRef(ContactForm),
    DETAILPANEL: ContactDetailsPanel,
    HASDETAIL: true,
    TABLEHEADERS: [
        { title: '', key: 'avatar' },
        { title: 'Name', key: 'name' },
        { title: 'Email', key: 'accountsEmail' },
        { title: 'Phone', key: 'phone' },
        { title: 'Primary Contact', key: 'primaryContact' },
        { title: 'Group', key: 'contactGroup.name' },
        { title: '', key: 'controls' }
    ],
    PANELS: [
        { title: 'Performance', component: ContactPerformancePanel, size: 12, inCard: true, autoHeight: true },
        { title: 'Jobs', component: JobTablePanel, size: 12, inCard: true, autoHeight: true },
        { title: 'Mail', component: MailPanel, size: 12, inCard: true },
        { title: 'Invoices', component: InvoicesTablePanel, size: 6, inCard: true },
    ]
}