import { shallowRef, ref, computed } from 'vue'
import WarehouseTransactionForm from '../../forms/WarehouseTransactionFrom.vue';

export default {
    ICON: 'mdi-warehouse',
    TYPE: 'WarehouseTransaction',
    SINGULAR: 'Transaction',
    PLURAL: 'Warehouse Transactions',
    ROUTE: 'warehouse/',
    DELETE: null,
    CREATE: 'warehouse/create/',
    UPDATE: 'warehouse/',
    FORM: shallowRef(WarehouseTransactionForm),
    HASDETAIL: false,
    TABLEHEADERS: [
        { title: 'Date', key: 'transactionDate', width: "10%" },
        { title: 'Type', key: 'type', width: "20%" },
        { title: 'Transaction', key: 'transaction', width: "30%" },
        { title: 'Contact', key: 'contact.name', width: "30%" },
        { title: 'Created By', key: 'user', width: "15%" },
    ],
    PANELS: [
    ]
}